var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row flex-wrap",style:({
    height: '100%',
  }),attrs:{"id":"signIn"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-wrap flex-shrink-0 justify-center align-center"},[_c('div',{staticClass:"py-3 px-3",staticStyle:{"position":"absolute","left":"2%","top":"2%"}},[_c('img',{staticClass:"cursor-pointer",staticStyle:{"width":"120px"},attrs:{"src":require("@/assets/logo.jpeg"),"alt":"National Commission On Culture"},on:{"click":function($event){return _vm.$router.push({ name: 'accounts.signin' })}}})]),_c('div',{staticStyle:{"min-height":"300px"},attrs:{"id":"loginCard"}},[_vm._m(0),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.resetPassword)}}},[_c('ValidationProvider',{attrs:{"name":"newPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mt-2",attrs:{"type":_vm.fieldState.newPassword ? 'text' : 'password',"placeholder":"Enter a new password ","label":"New Password","append-icon":_vm.fieldState.newPassword ? 'visibility_off' : 'visibility',"outlined":"","error-messages":errors[0],"autofocus":true},on:{"click:append":function($event){return _vm.changeState('newPassword')}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirmPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mt-2",attrs:{"type":_vm.fieldState.confirmPassword ? 'text' : 'password',"placeholder":"Confirm your new password ","label":"Confirm New Password","append-icon":_vm.fieldState.confirmPassword ? 'visibility_off' : 'visibility',"outlined":"","error-messages":errors[0],"autofocus":true},on:{"click:append":function($event){return _vm.changeState('confirmPassword')}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)]}}],null,true)}),_c('v-card-actions',{staticClass:"justify-end mt-1 pl-0 pr-0 pb-2"},[_c('v-btn',{staticClass:"ft text-sm text-capitalize",attrs:{"type":"submit","disabled":_vm.isLoading,"loading":_vm.isLoading,"color":"primary","large":"","block":"","depressed":""}},[_vm._v("Reset my password ")])],1)],1)]}}])})],1),_c('div',{staticClass:"d-flex flex-column align-center mt-2"},[_c('router-link',{attrs:{"to":"/signin"}},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Back to log in")])])],1),_vm._m(1)]),_vm._m(2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center animate__animated animate__fadeInDown d-flex flex-column justify-center pt-7 pl-1"},[_c('h4',{staticClass:"ft text-center font-weight-bold mt-2",staticStyle:{"font-size":"20px"}},[_vm._v(" Set new password. ")]),_c('p',{staticClass:"text-center font-weight-medium ft px-2 mt-2"},[_vm._v(" Provide below details to reset your password. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-center"},[_c('span',{staticClass:"white--text ft"},[_vm._v("National Commission On Culture ®")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-grow-1 container-view",staticStyle:{"flex-basis":"45%"},attrs:{"id":"home-description-container"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 align-center justify-center"},[_c('img',{staticClass:"animate__animated animate__fadeIn",staticStyle:{"width":"500px","height":"auto"},attrs:{"src":require("@/assets/images/security.svg"),"alt":"Forgot Password"}})])])}]

export { render, staticRenderFns }